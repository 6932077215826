import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { NwEnvironment } from "../Environment/NwEnvironment";
import FromToFilter from "../Interfaces/Models/FromToFilter";

export function GetTenantExport(instance: IPublicClientApplication, tenantId: string, fromToFilter: FromToFilter, callback: Function) {
    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => response.accessToken)
        .then((accessToken) => {
            fetch(NwEnvironment.apiBaseUrl + 'Export/tenant/' + tenantId,
                {
                    mode: 'cors',
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }),
                    body: JSON.stringify(fromToFilter)
                })
                .then(async response => {
                    return await response.blob();
                })
                .then(data => {
                    callback(data)
                });
        })
}

export function GetConsumerExport(instance: IPublicClientApplication, tenantId: string | undefined, consumerRfid: string, fromToFilter: FromToFilter, callback: Function) {
    instance.setActiveAccount(instance.getAllAccounts()[0])
    instance.acquireTokenSilent(loginRequest)
        .then(response => response.accessToken)
        .then((accessToken) => {
            fetch(`${NwEnvironment.apiBaseUrl}Export/tenant/${tenantId}/user/${consumerRfid}`,
                {
                    mode: 'cors',
                    method: 'post',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }),
                    body: JSON.stringify(fromToFilter)
                })
                .then(async response => {
                    return await response.blob();
                })
                .then(data => {
                    callback(data)
                });
        })
}