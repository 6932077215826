import { Box, Button, Flex, Grid, GridItem, Icon, IconButton, Text, Tooltip, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import Consumer from "../Interfaces/Models/Consumer";
import { GoLocation } from 'react-icons/go'
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { BsCloudDownload, BsPerson } from "react-icons/bs";
import React from "react";
import UpsertConsumerRequest from "../Interfaces/Requests/UpsertConsumerRequest";
import { UpdateConsumer, DeleteConsumer } from "../Repositories/TenantsRepository";
import ConfirmDeleteConsumerModal from "./ConfirmDeleteConsumerModal";
import UpsertConsumerModal from "./UpsertConsumerModal";

export default function ConsumerCard(props: any) {
    const tenantId = props.tenantId
    const model = props.model as Consumer
    const name = `${model.firstname} ${model.lastname}`

    const initialRef = React.useRef(null)
    const { isOpen: isUpsertOpen, onOpen: onUpsertOpen, onClose: onUpsertClose } = useDisclosure()
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

    const callbackOnConsumerUpdated = () => {
        props.callback();
        onUpsertClose();
    }

    const callbackOnConsumerDeleted = () => {
        props.callback();
        onDeleteClose();
    }

    const onUpdateCallback = (data: UpsertConsumerRequest) => {
        UpdateConsumer(tenantId, data, props.instance, props.ownerId, callbackOnConsumerUpdated)
    }

    const onDeleteCallback = () => {
        DeleteConsumer(tenantId, model.id, props.instance, props.ownerId, callbackOnConsumerDeleted)
    }

    const onClickExport = () => {
        var consumerId = model.id;
        console.log("Getting Export for: " + consumerId)
        props.callbackSetExportData(model);
    }

    return (
        <>
            <Box >
                <Box
                    maxW={'300px'}
                    minWidth={'300px'}
                    maxH={'250px'}
                    minHeight={'250px'}

                    bg={useColorModeValue('white', 'gray.100')}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    p={6}>
                    <Box
                        h={'100px'}
                        bg={'orange'}
                        mt={-6}
                        mx={-6}
                        mb={6}
                        pos={'relative'}>
                        <Grid
                            templateAreas={`"header header header"
                                            "nav main symbol"
                                            "nav footer symbol"`}
                            gridTemplateRows={'50px 20px 30px'}
                            gridTemplateColumns={'60px 160px 80px'}

                            color='blackAlpha.700'
                            fontWeight='bold'>

                            <GridItem area={'header'}>
                                <Text
                                    noOfLines={1}
                                    color={'white'}
                                    py={6}
                                    px={6}
                                    fontSize={'lg'}
                                    letterSpacing={1.1}
                                    maxWidth={300}
                                    maxHeight={50}>
                                    {name}
                                </Text>
                            </GridItem>
                            <GridItem area={'nav'}>
                                <Icon
                                    color={'white'}
                                    as={GoLocation}
                                    fontSize={25}
                                    display={'block'}
                                    mt={3}
                                    ml={4}
                                    alignContent={'center'} />
                            </GridItem>

                            <GridItem area={'main'}>
                                <Text
                                    pt={1}
                                    fontSize={'smaller'}
                                    color={'white'}
                                    maxWidth={160}
                                    maxHeight={'24px'}
                                    noOfLines={1}>
                                    {model.street} {model.housenumber}
                                </Text>
                            </GridItem>
                            <GridItem area={'footer'}>
                                <Text
                                    fontSize={'smaller'}
                                    color={'white'}
                                    maxWidth={160}
                                    maxHeight={'24px'}
                                    noOfLines={1}>
                                    {model.postalCode} {model.cityname}
                                </Text>
                            </GridItem>
                            <GridItem area={'symbol'}>
                                <Icon
                                    mx={6}
                                    as={BsPerson}
                                    color={'white'}
                                    fontSize={35} />
                            </GridItem>
                        </Grid>
                    </Box>

                    <Grid>
                        <GridItem>
                            <Grid
                                templateAreas={`"consumers  export"
                                            "rfid      rfid"
                                            "blank      ud"`}
                                gridTemplateRows={'50px 50px'}
                                gridTemplateColumns={'160px 105px'}>

                                <GridItem area={'consumers'}>
                                    <Text color={'gray.500'} fontSize={'xs'} ml={3}>Diesen Monat</Text>
                                    <Text ml={3}>{model.kwH} kW/h</Text>
                                </GridItem>

                                <GridItem area={'export'}>
                                    <Flex justifyContent={'right'} align={'center'}>
                                        <Button size={'md'} onClick={onClickExport}>
                                            <Text mr={2}>Export</Text>
                                            <Icon as={BsCloudDownload} fontSize={'1rem'} />
                                        </Button>
                                    </Flex>
                                </GridItem>
                                <GridItem area={'rfid'} >
                                    <Text color={'gray.500'} fontSize={'xs'} ml={3}>Rfid</Text>
                                    <Text noOfLines={1} ml={3}>{model.rfid}</Text>
                                </GridItem>
                                <GridItem area={'ud'}>
                                    <Flex justifyContent={'right'} align={'center'}>
                                        <IconButton
                                            variant='outline'
                                            aria-label='Edit Consumer'
                                            size={'xs'}
                                            icon={<AiFillEdit />}
                                            onClick={onUpsertOpen} />
                                        <IconButton
                                            variant='outline'
                                            aria-label='Delete Consumer'
                                            size={'xs'}
                                            icon={<AiFillDelete />}
                                            onClick={onDeleteOpen} />
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </Box >
            </Box >

            <UpsertConsumerModal
                onConfirmCallback={onUpdateCallback}
                initialRef={initialRef}
                isOpen={isUpsertOpen}
                onClose={onUpsertClose}
                title="Konsumenten bearbeiten"
                model={model}
                confirmText="Bestätigen"
            />

            <ConfirmDeleteConsumerModal
                onConfirmCallback={onDeleteCallback}
                initialRef={initialRef}
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                model={model}
            />
        </>
    )
}