import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, Input, FormErrorMessage, Flex, ModalFooter, Button, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useState } from "react";
import Tenant from "../Interfaces/Models/Tenant";
import { TenantSummary } from "../Interfaces/Models/TenantSummary";
import { AddCustomerAdminRow } from "./AddCustomerAdminRow";
import { CustomerAdminRow } from "./CustomerAdminRow";
import { CustomerAdminInfo } from "../Interfaces/Models/CustomerAdminInfo";

export default function UpsertTenantModal(props: any) {
    const model = props.model as TenantSummary;
    var pressed = false;

    const [tenantName, setTenantName] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.name
        }

        return res;
    })
    const [tenantStreet, setTenantStreet] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.street
        }

        return res;
    })
    const [tenantHousenumber, setTenantHousenumber] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.housenumber
        }

        return res;
    })
    const [tenantPostalCode, setTenantPostalCode] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.postalCode
        }

        return res;
    })
    const [tenantCityName, setTenantCityName] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.cityname
        }

        return res;
    })

    const [customerAdmins, setcustomerAdmins] = useState(() => {
        var res = Array<CustomerAdminInfo>()
        if (model != null) {
            res = model.admins
        }

        return res;
    })

    const onNameChange = (e: any) => setTenantName(e.target.value)
    const onStreetChange = (e: any) => setTenantStreet(e.target.value)
    const onHousenumberChange = (e: any) => setTenantHousenumber(e.target.value)
    const onPostalCodeChange = (e: any) => setTenantPostalCode(e.target.value)
    const onCityChange = (e: any) => setTenantCityName(e.target.value)

    const isTenantNameError = ValidateTenantName(tenantName)

    const onConfirmHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model === undefined ? '' : model.id,
                "name": tenantName,
                "street": tenantStreet,
                "housenumber": tenantHousenumber,
                "postalCode": tenantPostalCode,
                "cityname": tenantCityName,
                "admins": customerAdmins
            } as Tenant
            props.onConfirmCallback(data)
        }
    }

    const onAddAdminCallback = (adminInfoData: any) => {
        setcustomerAdmins(admins => [...admins, adminInfoData])
    }

    const onRemoveAdminCallback = (adminInfoData: any) => {
        var index = customerAdmins.findIndex(item => item.id === adminInfoData.id);

        customerAdmins.splice(index, 1);
        setcustomerAdmins(admins => [...admins])
    }

    const hasValidationErrors = () => { return isTenantNameError }

    return (
        <Modal
            isCentered
            initialFocusRef={props.initialRef}
            isOpen={props.isOpen}
            onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl isInvalid={isTenantNameError}>
                        <Input
                            type={'text'}
                            ref={props.initialRef}
                            placeholder='Name'
                            onChange={onNameChange}
                            value={tenantName} />
                        {
                            !isTenantNameError
                                ? <></>
                                : (<FormErrorMessage>Bitte geben Sie dem Objekt einen Namen</FormErrorMessage>)
                        }
                    </FormControl>

                    <FormControl mt={4}>
                        <Flex>
                            <Input onChange={onStreetChange} flex={4} type={'text'} placeholder='Straße' value={tenantStreet} />
                            <Input onChange={onHousenumberChange} ml={1} flex={1} type={'text'} placeholder='Nummer' value={tenantHousenumber} />
                        </Flex>
                    </FormControl>

                    <FormControl mt={4}>
                        <Flex>
                            <Input onChange={onPostalCodeChange} flex={1} type={'number'} placeholder='PLZ' value={tenantPostalCode} />
                            <Input onChange={onCityChange} ml={1} flex={4} type={'text'} placeholder='Ort' value={tenantCityName} />
                        </Flex>
                    </FormControl>

                    <h1>Admins</h1>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Email</Th>
                                <Th isNumeric>Aktion</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                customerAdmins.map((admin =>
                                    <CustomerAdminRow
                                        key={admin.email}
                                        model={admin}
                                        onDeleteCallback={onRemoveAdminCallback} />
                                ))
                            }
                            <AddCustomerAdminRow
                                maintainer={model}
                                currentAdmins={customerAdmins}
                                onConfirmCallback={onAddAdminCallback} />
                        </Tbody>
                    </Table>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={onConfirmHandler}
                        disabled={pressed || hasValidationErrors()}
                        color={'white'}
                        bg={'orange'}
                        size='lg'
                        _hover={{
                            bg: 'brand.400',
                            color: 'white',
                        }}
                        mr={3}>
                        {props.confirmText}
                    </Button>
                    <Button onClick={props.onClose}>Abbrechen</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

function ValidateTenantName(tenantName: string) {
    return tenantName === '';
}