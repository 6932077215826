import { Tr, Td, Text, IconButton, Tooltip } from "@chakra-ui/react";
import { AdminInfo } from "../Interfaces/Models/AdminInfo";
import { AiFillDelete } from "react-icons/ai";

export function AdminRow(props: any) {
    const model = props.model as AdminInfo
    var pressed = false;

    const onDeleteHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model.id,
                "email": model.email
            } as AdminInfo
            props.onDeleteCallback(data)
        }
    }

    const onMouseOverEmail = () => {
        console.log(model.email)
    }

    return (<>
        <Tr>
            <Td>
                <Tooltip label={model.email}>
                    <Text wordBreak={'break-word'} onMouseOver={onMouseOverEmail} maxW={'148rem'} noOfLines={1}>
                        {model.email}
                    </Text>
                </Tooltip>
            </Td>
            <Td isNumeric>
                <IconButton
                    variant='outline'
                    aria-label='Delete Tenant'
                    size={'xs'}
                    icon={<AiFillDelete />}
                    onClick={onDeleteHandler} />
            </Td>
        </Tr>
    </>);
}