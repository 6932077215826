import {
    Box,
    Text,
    useColorModeValue,
    Icon,
    Grid,
    GridItem,
    Flex,
    Button,
    IconButton,
    useDisclosure,
} from '@chakra-ui/react';
import { BsArrowReturnRight, BsBuilding, BsCloudDownload, BsPeople } from 'react-icons/bs';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go'
import { TenantSummary } from '../Interfaces/Models/TenantSummary';
import UpsertTenantModal from './UpsertTenantModal';
import React from 'react';
import Tenant from '../Interfaces/Models/Tenant';
import { DeleteTenant, UpdateTenant } from '../Repositories/TenantsRepository';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { useNavigate } from 'react-router-dom'

export default function TenantCard(props: any) {
    let navigate = useNavigate();
    const model = props.model as TenantSummary;
    const initialRef = React.useRef(null)
    const { isOpen: isUpsertOpen, onOpen: onUpsertOpen, onClose: onUpsertClose } = useDisclosure()
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()

    const callbackOnTenantUpdated = () => {
        props.callback();
        onUpsertClose();
    }

    const callbackOnTenantDeleted = () => {
        props.callback();
        onDeleteClose();
    }

    const onClickExport = () => {
        var tenantId = model.id;
        console.log("Getting Export for: " + tenantId)
        props.callbackSetExportData(model);
    }

    const onUpdateCallback = (data: Tenant) => {
        UpdateTenant(data, props.instance, props.ownerId, callbackOnTenantUpdated)
    }

    const onDeleteCallback = () => {
        DeleteTenant(model.id, props.instance, props.ownerId, callbackOnTenantDeleted)
    }

    const onDetailsClicked = () => {
        navigate(`/${props.ownerId}/tenants/${model.id}`);
    }

    return (
        <>
            <Box >
                <Box
                    maxW={'300px'}
                    minWidth={'300px'}
                    maxH={'250px'}
                    minHeight={'250px'}

                    bg={useColorModeValue('white', 'gray.100')}
                    boxShadow={'2xl'}
                    rounded={'md'}
                    p={6}>
                    <Box
                        h={'100px'}
                        bg={'orange'}
                        mt={-6}
                        mx={-6}
                        mb={6}
                        pos={'relative'}>
                        <Grid
                            templateAreas={`"header header header"
                                        "nav main symbol"
                                        "nav footer symbol"`}
                            gridTemplateRows={'50px 20px 30px'}
                            gridTemplateColumns={'60px 160px 80px'}

                            color='blackAlpha.700'
                            fontWeight='bold'>

                            <GridItem area={'header'}>
                                <Text
                                    color={'white'}
                                    py={6}
                                    px={6}
                                    fontSize={'lg'}
                                    noOfLines={1}
                                    letterSpacing={1.1}
                                    maxWidth={300}
                                    maxHeight={50}>
                                    {model.name}
                                </Text>
                            </GridItem>
                            <GridItem area={'nav'}>
                                <Icon
                                    color={'white'}
                                    as={GoLocation}
                                    fontSize={25}
                                    display={'block'}
                                    mt={3}
                                    ml={4}
                                    alignContent={'center'} />
                            </GridItem>

                            <GridItem area={'main'}>
                                <Text
                                    pt={1}
                                    fontSize={'smaller'}
                                    color={'white'}
                                    maxWidth={160}
                                    maxHeight={'24px'}
                                    noOfLines={1}
                                    className={'might-overflow'}>
                                    {model.street} {model.housenumber}
                                </Text>
                            </GridItem>
                            <GridItem area={'footer'}>
                                <Text
                                    fontSize={'smaller'}
                                    color={'white'}
                                    maxWidth={160}
                                    maxHeight={'24px'}
                                    noOfLines={1}>
                                    {model.postalCode} {model.cityname}
                                </Text>
                            </GridItem>
                            <GridItem area={'symbol'}>
                                <Icon
                                    mx={6}
                                    as={BsBuilding}
                                    color={'white'}
                                    fontSize={35} />
                            </GridItem>
                        </Grid>
                    </Box>

                    <Grid>
                        <GridItem>
                            <Grid
                                templateAreas={`"consumers  export"
                                            "totalkwh      details"
                                            "blank      ud"`}
                                gridTemplateRows={'50px 50px'}
                                gridTemplateColumns={'160px 105px'}>
                                <GridItem area={'consumers'}>
                                    <Flex justifyContent={'left'} align={'center'}>
                                        <Icon as={BsPeople}
                                            fontSize={25} />
                                        <Text ml={3}>{model.consumersCount}</Text>
                                    </Flex>
                                </GridItem>
                                <GridItem area={'export'}>
                                    <Flex justifyContent={'right'} align={'center'}>
                                        <Button size={'md'} onClick={onClickExport}>
                                            <Text mr={2}>Export</Text>
                                            <Icon as={BsCloudDownload} fontSize={'1rem'} />
                                        </Button>
                                    </Flex>
                                </GridItem>
                                <GridItem area={'totalkwh'}>
                                    <Text color={'gray.500'} fontSize={'xs'}>Diesen Monat</Text>
                                    <Text >{model.kwh} kW/h</Text>
                                </GridItem>
                                <GridItem area={'details'}>
                                    <Button size={'md'} onClick={onDetailsClicked}>
                                        <Flex justifyContent={'right'} align={'center'} >
                                            <Text mr={2}>Details</Text>
                                            <Icon as={BsArrowReturnRight} fontSize={'1rem'} />
                                        </Flex>
                                    </Button>
                                </GridItem>
                                <GridItem area={'ud'}>
                                    <Flex justifyContent={'right'} align={'center'}>
                                        <IconButton
                                            variant='outline'
                                            aria-label='Edit Tenant'
                                            size={'xs'}
                                            icon={<AiFillEdit />}
                                            onClick={onUpsertOpen} />
                                        <IconButton
                                            variant='outline'
                                            aria-label='Delete Tenant'
                                            size={'xs'}
                                            icon={<AiFillDelete />}
                                            onClick={onDeleteOpen} />
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </Box >
            </Box >

            <UpsertTenantModal
                onConfirmCallback={onUpdateCallback}
                initialRef={initialRef}
                isOpen={isUpsertOpen}
                onClose={onUpsertClose}
                title="Grundstück bearbeiten"
                model={model}
                confirmText="Bestätigen"
            />

            <ConfirmDeleteModal
                onConfirmCallback={onDeleteCallback}
                initialRef={initialRef}
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                model={model}
            />
        </>
    );
}
