import { Tr, Td, Input, Button, FormControl, FormErrorMessage, ring } from "@chakra-ui/react";
import { useState } from "react";
import { AdminInfo } from "../Interfaces/Models/AdminInfo";
import { ValidateEmail } from "../Helpers/EmailValidationHelper";

export function AddAdminRow(props: any) {
    const model = props.model as AdminInfo
    const currentAdmins = props.currentAdmins as AdminInfo[]

    var pressed = false;

    const [email, setEmail] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.email
        }

        return res;
    })


    const onEmailChange = (e: any) => setEmail(e.target.value)

    const onAddHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": '',
                "email": email
            } as AdminInfo
            props.onConfirmCallback(data)
        }
    }

    const isEmailError = ValidateEmail(email)
    const isDuplicateError = ValidateDuplicate(currentAdmins, email)
    const hasValidationErrors = () => { return isEmailError || isDuplicateError }

    return (<>
        <FormControl w={200} isInvalid={isEmailError || isDuplicateError}>
            <Tr>
                <Td>
                    <Input
                        w={240}
                        type={'text'}
                        ref={props.initialRef}
                        placeholder='Email'
                        onChange={onEmailChange}
                        value={email} />
                </Td>
                <Td>
                    <Button
                        w={15}
                        ml={15}
                        onClick={onAddHandler}
                        disabled={pressed || hasValidationErrors()}
                        color={'white'}
                        bg={'orange'}
                        size='lg'
                        _hover={{
                            bg: 'brand.400',
                            color: 'white',
                        }}>
                        +
                    </Button>
                </Td>
            </Tr>
            {
                !isEmailError
                    ? <></>
                    : (<FormErrorMessage>Bitte geben Sie dem Admin eine gültige Email Adresse</FormErrorMessage>)
            }
            {
                !isDuplicateError
                    ? <></>
                    : (<FormErrorMessage>Diese Email ist bereits als Admin eingetragen</FormErrorMessage>)
            }
        </FormControl>
    </>)
}

function ValidateDuplicate(existingAdmins: AdminInfo[], email: string): boolean {
    return existingAdmins.some(admin => admin.email === email)
}