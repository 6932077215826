import { Button, Flex, FormControl, FormErrorMessage, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useState } from "react";
import Consumer from "../Interfaces/Models/Consumer";
import UpsertConsumerRequest from "../Interfaces/Requests/UpsertConsumerRequest";
import { ValidateEmail } from "../Helpers/EmailValidationHelper";

export default function UpsertConsumerModal(props: any) {
    const model = props.model as Consumer;
    var pressed = false;

    const [firstname, setFirstname] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.firstname
        }

        return res;
    })

    const [lastname, setLastname] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.lastname
        }

        return res;
    })

    const [street, setStreet] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.street
        }

        return res;
    })

    const [housenumber, setHousenumber] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.housenumber
        }

        return res;
    })

    const [postalCode, setPostalCode] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.postalCode
        }

        return res;
    })

    const [cityName, setCityName] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.cityname
        }

        return res;
    })

    const [rfid, setRfid] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.rfid
        }

        return res;
    })

    const [email, setEmail] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.email
        }

        return res;
    })

    const [graphid, setGraphId] = useState(() => {
        var res = ''
        if (model != null) {
            res = model.graphId
        }

        return res;
    })

    const onFirstnameChange = (e: any) => setFirstname(e.target.value)
    const onLastnameChange = (e: any) => setLastname(e.target.value)
    const onStreetChange = (e: any) => setStreet(e.target.value)
    const onHousenumberChange = (e: any) => setHousenumber(e.target.value)
    const onPostalCodeChange = (e: any) => setPostalCode(e.target.value)
    const onCityChange = (e: any) => setCityName(e.target.value)
    const onRfidChange = (e: any) => setRfid(e.target.value)
    const onEmailChange = (e: any) => setEmail(e.target.value)

    const onConfirmHandler = () => {
        if (!pressed) {
            pressed = true;
            var data = {
                "id": model === undefined ? '' : model.id,
                "firstname": firstname,
                "lastname": lastname,
                "street": street,
                "housenumber": housenumber,
                "postalCode": postalCode,
                "cityname": cityName,
                "rfid": rfid,
                "email": email,
                "graphId": graphid
            } as UpsertConsumerRequest
            props.onConfirmCallback(data)
        }
    }

    const isLastNameError = LastnameIsEmpty(lastname)
    const hasValidationErrors = () => { return isLastNameError }

    return (
        <Modal
            isCentered
            initialFocusRef={props.initialRef}
            isOpen={props.isOpen}
            onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{props.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl isInvalid={isLastNameError}>
                        <FormControl >
                            <Flex>
                                <Input
                                    type={'text'}
                                    ref={props.initialRef}
                                    placeholder='Vorname'
                                    onChange={onFirstnameChange}
                                    value={firstname} />
                                <Input isInvalid={isLastNameError}
                                    ml={1}
                                    type={'text'}
                                    placeholder='Nachname'
                                    onChange={onLastnameChange}
                                    value={lastname} />
                            </Flex>

                        </FormControl>

                        <FormControl mt={4}>
                            <Flex>
                                <Input onChange={onStreetChange}
                                    flex={4}
                                    type={'text'}
                                    placeholder='Straße'
                                    value={street} />
                                <Input onChange={onHousenumberChange}
                                    ml={1}
                                    flex={1}
                                    type={'text'}
                                    placeholder='Nummer'
                                    value={housenumber} />
                            </Flex>
                        </FormControl>

                        <FormControl mt={4}>
                            <Flex>
                                <Input onChange={onPostalCodeChange}
                                    flex={1}
                                    type={'number'}
                                    placeholder='PLZ'
                                    value={postalCode} />
                                <Input onChange={onCityChange}
                                    ml={1}
                                    flex={4}
                                    type={'text'}
                                    placeholder='Ort'
                                    value={cityName} />
                            </Flex>
                        </FormControl>

                        <FormControl mt={4}>
                            <Flex>
                                <Input onChange={onRfidChange}
                                    flex={1}
                                    type={'text'}
                                    placeholder='Rfid'
                                    value={rfid} />
                            </Flex>
                        </FormControl>

                        <FormControl mt={4} >
                            <Flex>
                                <Input onChange={onEmailChange}
                                    flex={1}
                                    type={'text'}
                                    placeholder='Email'
                                    value={email} />
                            </Flex>

                        </FormControl>
                        {
                            !isLastNameError
                                ? <></>
                                : (<FormErrorMessage>Bitte geben Sie dem Nutzer einen gültige</FormErrorMessage>)
                        }
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        disabled={hasValidationErrors()}
                        onClick={onConfirmHandler}
                        color={'white'}
                        bg={'orange'}
                        size='lg'
                        _hover={{
                            bg: 'brand.400',
                            color: 'white',
                        }}
                        mr={3}>
                        {props.confirmText}
                    </Button>
                    <Button onClick={props.onClose}>Abbrechen</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

function LastnameIsEmpty(lastname: string): boolean {
    return lastname === '';
}